var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loading || _vm.localLoading,"headers":_vm.computedHeaders,"disable-sort":!true,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":"0","dense":true},scopedSlots:_vm._u([{key:"header.source_address",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require('../../../assets/IconOriginMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.destination_address",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require('../../../assets/IconDestinationMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"top",fn:function(){return [_c('v-row',{staticClass:"\n          d-flex\n          flex-column flex-md-row\n          align-center\n          justify-md-space-between\n        ",attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row align-center mt-2",class:_vm.$vuetify.breakpoint.mdAndUp ? 'ml-2' : 'pa-2',attrs:{"md":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Search'),"prepend-inner-icon":_vm.icons.mdiMagnify,"outlined":"","dense":"","clearable":"","disabled":_vm.loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"\n            d-flex\n            flex-row\n            justify-end\n            align-center\n            mb-2\n            color-blue-text\n          ",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleRefresh()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Refresh table"))+" ")])])],1)])],1)]},proxy:true},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [(item.is_corporate_user)?_c('a',{attrs:{"target":"_blank","href":("/user/" + (item.user_id))}},[_vm._v(" "+_vm._s(item.user_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.user_name)+" ")]),_c('br'),_c('a',{attrs:{"href":("tel:" + (item.user_phone))}},[_vm._v(" "+_vm._s(item.user_phone.substring(3))+" ")])]}},{key:"item.total",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]}},{key:"item.provider_name",fn:function(ref){
var item = ref.item;
return [(!item.provider_name)?_c('span',[_vm._v(_vm._s(_vm.$t("No driver")))]):_c('a',{attrs:{"target":"_blank","href":("/provider/" + (item.provider_id) + "?only_shipments=1")}},[_vm._v(" "+_vm._s(item.provider_name)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(!item.server_start_time_for_schedule)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD-MM-YYYY h:mm a"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.server_start_time_for_schedule,"DD-MM-YYYY h:mm a"))+" ")])]}},{key:"item.trip_status",fn:function(ref){
var item = ref.item;
return [(
          item.trip_status === 'Waiting' &&
          !item.server_start_time_for_schedule
        )?_c('v-chip',{staticClass:"v-chip-light-bg error--text",attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip_status)))]):_vm._e(),(
          item.trip_status === 'Waiting' &&
          item.server_start_time_for_schedule
        )?_c('v-chip',{staticClass:"v-chip-light-bg primary--text",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Scheduled")))]):(item.trip_status === 'Accepted')?_c('v-chip',{staticClass:"v-chip-light-bg warning--text",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip_status)))]):(item.trip_status === 'Coming')?_c('v-chip',{staticClass:"v-chip-light-bg warning--text",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip_status)))]):(item.trip_status === 'Arrived')?_c('v-chip',{staticClass:"v-chip-light-bg success--text",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip_status)))]):(item.trip_status === 'Started')?_c('v-chip',{staticClass:"v-chip-light-bg success--text",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip_status)))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"rounded":".rounded-lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleShowUser(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("View user")))])],1),(item.provider_name)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleShowProvider(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("View provider")))])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleViewMap(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("View map")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleShareTrip(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("copy_parcel_details")))])],1),(item.trip_status !== 'Started')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleCancel(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("parcel_cancel")))])],1):_vm._e()],1)],1)]}},{key:"item.unique_id",fn:function(ref){
        var item = ref.item;
return [_c('span',[_c('td',[_c('a',{attrs:{"target":"_blank","href":("/view-map/" + (item._id))}},[_vm._v(" "+_vm._s(item.unique_id)+" ")]),(item.is_multi_stop)?_c('span',[_c('br'),_c('v-chip',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.handleMultiStopModal(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMapMarkerPath)+" ")]),_vm._v(" "+_vm._s(_vm.$t("multi_stop"))+" ")],1)],1):_c('span',[(!item.is_multi_stop)?_c('span',[_c('br'),_c('v-chip',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.handleParcelDetailsModal(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPackageVariantClosed)+" ")]),_vm._v(" Envío ")],1)],1):_vm._e()]),(Boolean(item.api_partner_id))?_c('APITripChip'):_vm._e()],1)])]}},{key:"item.destination_address",fn:function(ref){
        var item = ref.item;
return [(item.is_multi_stop)?_c('td',{staticClass:"d-flex flex-row align-baseline"},[_vm._v(" "+_vm._s(item.destination_address)+" "),_c('span',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.handleMultiStopModal(item)}}},[_c('v-badge',{attrs:{"right":"","color":"warning","content":item.routes.length,"offset-y":"10"}})],1)]):_c('td',[_vm._v(" "+_vm._s(item.destination_address)+" ")])]}},{key:"item.serviceType",fn:function(ref){
        var value = ref.value;
return [(value)?_c('span',{staticClass:"d-flex flex-column justify-center align-center mt-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"bs-tooltip-top arrow","max-width":300,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',{staticClass:"d-flex flex-column justify-center align-center"},[_c('v-img',{attrs:{"src":("" + _vm.bucketUrl + (value.type_image_url)),"max-width":"80"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('span',{staticClass:"fontUrbanist font-weight-bold"},[_vm._v(_vm._s(value.typename))])],1)]}}],null,true)},[_c('span',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-icon',{style:(!_vm.$vuetify.theme.isDark && 'color: #ffffff !important;'),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccount)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(value.max_space)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" | ")]),_c('v-icon',{staticClass:"ml-2",style:(!_vm.$vuetify.theme.isDark && 'color: #ffffff !important;'),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBagSuitcase)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(value.baggages)+" ")])],1)])],1):_c('span')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }