<template>
  <span>
    <br />
    <v-chip small>
      <v-icon center size="18">
        {{ icons.mdiApi }}
      </v-icon>
    </v-chip>
  </span>
</template>
<script>
import { mdiApi } from "@mdi/js";
export default {
  props: {
    isBr: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      icons: {
        mdiApi,
      },
    };
  },
};
</script>
<style lang="scss" scoped></style>
